const projectId = 'careerscope-dev';

export const environment = {
  dev: false,
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: "AIzaSyCvkpWgRB1yoe0OdeMYNrizn1R5F061tpI",
    authDomain: "careerscope-dev.firebaseapp.com",
    projectId,
    storageBucket: "careerscope-dev.appspot.com",
    messagingSenderId: "744158600910",
    appId: "1:744158600910:web:a1afadcc30906db91bfa9f"
  },
  functionsURL: `https://app-staging.careerscope.com`
};