import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import packageInfo from '@career-scope/version';
import { init, replayIntegration, browserTracingIntegration, createErrorHandler, TraceService } from "@sentry/angular";
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app/app-routing';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgIdleModule } from '@ng-idle/core';
import { AppComponent } from './app/app.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';

console.log('version', packageInfo.version);

init({
    environment: environment.production ? 'production' : environment.dev ? 'development' : 'staging',
    release: "career-scope@" + packageInfo.version,
    dsn: "https://77594aba3c9c47468a42412b4c576d0e@o114829.ingest.sentry.io/4505449318449152",
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    integrations: [
        browserTracingIntegration(),
        replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: environment.production ? 0 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(NgIdleModule.forRoot()),
        Title,
        provideRouter(routes,
            // withViewTransitions(),
            withInMemoryScrolling({
              scrollPositionRestoration: "top",
            })
          ),
        // Below is used to run firestore, functions, and auth emulators 
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const firestore = getFirestore();
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 9001);
            }
            return firestore;
        }),
        provideFunctions(() => {
            const functions = getFunctions();
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            functions.customDomain = environment.functionsURL;
            return functions;
        }),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: false });
            }
            return auth;
        }),
        provideEnvironmentNgxMask(),
        // Sentry Error Handler
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => { },
            deps: [TraceService],
            multi: true,
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ]
})
    .catch(err => console.error(err));
